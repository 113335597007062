exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dev-services-js": () => import("./../../../src/pages/dev-services.js" /* webpackChunkName: "component---src-pages-dev-services-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentions-js": () => import("./../../../src/pages/mentions.js" /* webpackChunkName: "component---src-pages-mentions-js" */),
  "component---src-pages-merci-js": () => import("./../../../src/pages/merci.js" /* webpackChunkName: "component---src-pages-merci-js" */),
  "component---src-pages-seo-services-js": () => import("./../../../src/pages/seo-services.js" /* webpackChunkName: "component---src-pages-seo-services-js" */),
  "component---src-template-archive-js": () => import("./../../../src/template/archive.js" /* webpackChunkName: "component---src-template-archive-js" */),
  "component---src-template-blog-details-js": () => import("./../../../src/template/blog-details.js" /* webpackChunkName: "component---src-template-blog-details-js" */),
  "component---src-template-category-post-js": () => import("./../../../src/template/category-post.js" /* webpackChunkName: "component---src-template-category-post-js" */),
  "component---src-template-project-details-js": () => import("./../../../src/template/project-details.js" /* webpackChunkName: "component---src-template-project-details-js" */),
  "component---src-template-tag-template-js": () => import("./../../../src/template/tag-template.js" /* webpackChunkName: "component---src-template-tag-template-js" */)
}

